// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import * as pluralise from 'pluralise';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Config
import { DEPARTURE_STATUS, TARGET_SHEET_WIDTH } from '../../../../../../../utils/constants';

// Component(s)
import AdditionalContent from '../../content/AdditionalContent';
import ExtendedListRow from '../../generics/ExtendedListRow';
import Photo from './Photo';
import PncCheck from '../../checks/PncCheck';
import SystemChecksCompleted from '../../checks/SystemChecksCompleted';

// Util(s)
import {
  TargetCommonUtil,
  CommonUtil,
  CredibilityChecksUtil,
  DateTimeUtil,
  TargetPersonUtil,
} from '../../../../../../../utils';
import DocumentUtil from '../../../../../../../utils/Document/Uplift/documentUtil';

// Styling
import './PersonDetails.scss';

const PersonDetails = ({ id, person, personPosition, mode, preview }) => {
  const adjustedPersonPosition = personPosition + 1;
  const checksCompleted = CredibilityChecksUtil.getChecksWithoutPnc(person);
  const document = TargetPersonUtil.document(person);
  const iso2CountryOfIssueCode = DocumentUtil.countryOfIssue(document);
  const iso3CountryOfIssueCode = CommonUtil.iso3Code(iso2CountryOfIssueCode);
  const formattedCountryOfIssue = DocumentUtil.format.countryOfIssue(iso2CountryOfIssueCode);
  const additionalContents = TargetCommonUtil.additionalTextContents(person);
  const photoContent = TargetCommonUtil.additionalPhotoContent(person);
  const { width } = useWindowSize();

  const constructFullName = () => {
    let firstName = person?.name?.first
      .toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');
    return `${firstName} ${person?.name?.last.toUpperCase()}`;
  };

  const getMovementStatusTag = () => {
    if (!person?.passengerStatus) {
      return null;
    }

    const textType = () => {
      if (width < TARGET_SHEET_WIDTH) {
        return DEPARTURE_STATUS[person?.passengerStatus]?.code;
      }
      return DEPARTURE_STATUS[person?.passengerStatus]?.description;
    };

    return (
      <Tag
        id="summary-movement-description"
        className="tag tag--blue"
        text={textType()}
      />
    );
  };

  return (
    <div id="person-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-full">
        <div id="person-details-container" className="govuk-grid-row">
          <div id="person-details-display">
            <ExtendedListRow
              activeClass="badge-enabled"
              id={`person-${person.dateOfBirth}`}
              type="header-first"
              values={[
                constructFullName(),
                '',
                `Task ID: ${id}`,
                getMovementStatusTag(),
              ]}
            />
            <ExtendedListRow
              id={`person-${person.dateOfBirth}-${person?.seatNumber}`}
              type="body"
              values={[
                ['Nationality', `${person?.nationality?.nationality} - ${person?.nationality?.iso31661alpha3}`],
                ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                ['Gender', person?.gender?.name],
                ['Seat number', person?.seatNumber],
              ]}
            />
            <ExtendedListRow
              id={`person-${person?.dateOfBirth}-passport`}
              type="header"
              values={[
                'Passport',
                person?.document?.number,
                '',
                '',
              ]}
            />
            <ExtendedListRow
              id={`person-${person?.dateOfBirth}-issued`}
              type="body"
              values={[
                ['Issued by', `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`],
                ['Issue date', DateTimeUtil.format(person?.document?.validFrom, 'DD MMM YYYY')],
                ['Valid to', DateTimeUtil.format(person?.document?.expiry, 'DD MMM YYYY')],
                '',
              ]}
            />
            <AdditionalContent additions={additionalContents} heading="Addition" />
            <ExtendedListRow
              id={`person-${person?.dateOfBirth}-baggage-details`}
              type="header"
              values={[
                'Baggage',
                pluralise.withCount(person?.baggage?.numberOfCheckedBags, '% bag', '%bags'),
                `${person?.baggage?.weight} total`,
                person?.baggage?.tags,
              ]}
            />
          </div>
          {photoContent && <div className="person-photo"><Photo personPosition={adjustedPersonPosition} photoContent={photoContent} /></div>}
        </div>
        <div id="checks">
          <div id="left">
            <PncCheck
              person={person}
              personPosition={personPosition}
              mode={mode}
              preview={preview}
            />
          </div>
          <div id="right">
            <SystemChecksCompleted
              checksCompleted={checksCompleted}
              preview={preview}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PersonDetails.propTypes = {
  id: PropTypes.string.isRequired,
  person: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

PersonDetails.defaultProps = {
  preview: false,
};

export default memo(PersonDetails);
