// Global import(s)
import { SmallHeading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Styling
import './AdditionalContent.scss';

const AdditionalContent = ({ additions, heading, ...props }) => {
  if (!additions?.length) {
    return null;
  }

  return (
    <div {...props} className="additional-content-container">
      <div className="additional-content">
        <SmallHeading size="m">{heading}</SmallHeading>
        {additions.map((addition) => (
          <div key={addition.id} className="additional-content-row">
            {addition.content}
          </div>
        ))}
      </div>
    </div>
  );
};

AdditionalContent.propTypes = {
  additions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
  heading: PropTypes.string.isRequired,
};

AdditionalContent.defaultProps = {
  additions: [],
};

export default memo(AdditionalContent);
