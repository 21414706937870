// Global import(s)
import PropTypes from 'prop-types';
import { Heading } from '@ukhomeoffice/cop-react-components';
import React, { memo } from 'react';

// Component
import Itinerary from '../../../../../Task/Uplift/TaskDetails/components/shared/movement/voyage/shared/Itinerary';
import PreviousNext from '../generics/PreviousNext';

// Util(s)
import BookingUtil from '../../../../../../utils/Booking/bookingUtil';
import DateTimeUtil from '../../../../../../utils/Datetime/datetimeUtil';
import ExtendedListRow from '../generics/ExtendedListRow';
import renderBlock, { toPreviousTaskLinkParams, toPreviousTaskLink } from '../../../../../Task/TaskDetails/helper/common';

import './BookingDetails.scss';

const BookingDetails = ({ informationSheet }) => {
  const timeDiff = DateTimeUtil.timeDiff([informationSheet?.movement?.booking?.bookedAt, informationSheet?.movement?.journey?.departure?.time], 'Booked', 'before travel');
  const booking = BookingUtil.get(informationSheet);
  const agent = BookingUtil.agent(booking);
  const agentIata = informationSheet?.movement?.booking?.agentIataCode;
  const journey = informationSheet?.movement?.journey;
  const itinerary = journey?.itinerary;

  const toSentenceCase = (str) => {
    return str?.length ? `${str[0].toUpperCase()}${str.substr(1).toLowerCase()}` : '';
  };

  const getAgentIATA = () => {
    return renderBlock(
      'Agent IATA',
      {
        content: agentIata,
      },
      false,
      toPreviousTaskLink(toPreviousTaskLinkParams(informationSheet, agent)),
    );
  };

  return (
    <div id="booking-details" className="govuk-grid-row target-info-summary-list">
      <hr />
      <PreviousNext
        id="booking-details"
        index={0}
        size={1}
      />
      <Heading id="booking-details" size="l">Booking details</Heading>
      <div id="details" className="govuk-grid-column-full">
        <div className="govuk-grid-row">
          <ExtendedListRow
            id="booking"
            type="header-first-booking"
            values={[
              'Booking',
              timeDiff,
            ]}
          />
          <ExtendedListRow
            id="details"
            type="body"
            values={[
              ['Booking country', informationSheet?.movement?.booking?.bookingCountry?.isoCode],
              ['PNR locator', informationSheet?.movement?.booking?.pnrLocator],
              ['Ticket type', toSentenceCase(informationSheet?.movement?.booking?.ticketType)],
              getAgentIATA(),
            ]}
          />
          <Itinerary itinerary={itinerary} />
        </div>
      </div>
    </div>
  );
};

BookingDetails.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
};

export default memo(BookingDetails);
